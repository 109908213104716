body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2a2a;
  background-color: #ffffff;
  font-size: 18px;
}

.ant-btn-primary {
  color: #2a2a2a;
}

.ant-btn-primary:focus {
  background-color: #ecbe7a;
  border-color: #ecbe7a;
  color: #2a2a2a;
}

.ant-btn-primary:hover {
  background-color: #ecbe7a;
  transform: scale(1.1);
  color: #2a2a2a;
}

.ant-modal-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.ant-modal-header {
  border-radius: 20px;
}

.ant-modal-content {
  border-radius: 20px;
}

.slick-list {
  padding-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 46px;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

h2,
.h2 {
  font-size: 35px;
}

h3,
.h3 {
  font-size: 28px;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 18px;
}

.small {
  font-size: 80%;
  font-weight: 400;
}

.hero-icon {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: rgba(32, 32, 32, 0.5);
}

.color-primary {
  color: #ecbe7a;
}

.header {
  position: absolute;
  width: 100%;
  z-index: 99;
  transition: all 0.3s linear;
  color: #fff;
}

.header-light {
  position: fixed;
  top: 0;
  background-color: rgba(241, 241, 241, 0.95);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  color: #2a2a2a;
}

.footer {
  min-height: 5vh;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
}

.announcement {
  height: 78px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.btn-header {
  font-size: 1.15rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.header .btn-header:hover {
  color: #2a2a2a;
}

.header-light .btn-header:hover {
  color: #ecbe7a;
}

.btn-header-link {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: inherit;
}

.btn-header-link:hover {
  color: #ecbe7a;
}

.btn-header-mobile {
  font-size: 1.15rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-header-mobile:hover {
  color: #ecbe7a;
}

.align-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.team-img {
  object-fit: contain;
  height: 100%;
  max-height: 200px;
  width: auto;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hero-team-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
}

.logo-img {
  object-fit: contain;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.logo-img-small {
  object-fit: contain;
  width: 100%;
  max-width: 150px;
  height: auto;
}

.partner-img {
  object-fit: contain;
  height: 100%;
  max-height: 100px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hero-img {
  object-fit: cover;
  height: 100%;
  max-height: 375px;
  width: 100%;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
}

.hero-panel {
  background-color: #ecbe7a;
  height: 375px;
  width: 600px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
}

.section {
  margin-top: 6rem;
  margin-bottom: 6rem;
  scroll-margin-top: 8rem;
}

.section-inner {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.section-dark-left {
  background-image: linear-gradient(to right, #7cadc8, #8ac0de 55%, #ffffff 55%);
  width: 100%;
  height: 500px;
  padding: 2rem;
}

.section-light-right {
  background-image: linear-gradient(to right, #ffffff 45%, #f1f1f1 45%);
  width: 100%;
  height: 500px;
  padding: 2rem;
}

.section-light-left {
  background-image: linear-gradient(to right, #f1f1f1 55%, #ffffff 55%);
  width: 100%;
  height: 500px;
  padding: 2rem;
}

.section-light-bottom {
  background-image: linear-gradient(to bottom, #ffffff 30%, #f1f1f1 30%);
  width: 100%;
  min-height: 900px;
  padding: 2rem;
}

.section-light-thin {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  width: 100%;
  min-height: 120px;
  text-align: center;
}

.section-light-right-content {
  padding-left: 3rem;
}

.section-light-left-content {
  padding-right: 3rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/**** RESPONSIVE STYLES ****/

/* breakpoint xxl */

@media screen and (max-width: 1600px) {
}

/* breakpoint xl */

@media screen and (max-width: 1200px) {
}

/* breakpoint: lg */

@media screen and (max-width: 992px) {
  .tablet-hidden {
    visibility: hidden;
    display: none;
  }

  .section-dark-left {
    background-image: linear-gradient(to top, #ffffff 30%, #8ac0de 30%, #7cadc8);
    width: 100%;
    height: 800px;
    padding: 2rem;
  }

  .section-light-right {
    background-image: linear-gradient(to bottom, #ffffff 40%, #f1f1f1 40%);
    width: 100%;
    height: 850px;
    padding: 2rem;
  }

  .section-light-left {
    background-image: linear-gradient(to top, #ffffff 30%, #f1f1f1 30%);
    width: 100%;
    height: 700px;
    padding: 2rem;
  }

  .section-light-right-content {
    padding-left: 0;
    padding-top: 3rem;
  }

  .section-light-left-content {
    padding-right: 0;
    padding-bottom: 3rem;
  }

  .section-light-bottom {
    background-image: linear-gradient(to bottom, #ffffff 20%, #f1f1f1 20%);
    width: 100%;
    min-height: 900px;
    padding: 2rem;
  }

  .hero-panel {
    background-color: #ecbe7a;
    height: 275px;
    width: 600px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
  }

  .hero-img {
    object-fit: cover;
    max-width: 600px;
    height: 100%;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
  }
}

/* breakpoint: md */

@media screen and (max-width: 768px) {
  h1,
  .h1 {
    font-size: 38px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }

  .section-dark-left {
    background-image: linear-gradient(to top, #ffffff 30%, #8ac0de 30%, #7cadc8);
    width: 100%;
    height: 750px;
    padding: 2rem;
  }

  .section-light-right {
    background-image: linear-gradient(to bottom, #ffffff 30%, #f1f1f1 30%);
    width: 100%;
    height: 850px;
    padding: 0;
  }

  .section-light-left {
    background-image: linear-gradient(to top, #ffffff 30%, #f1f1f1 30%);
    width: 100%;
    height: 700px;
    padding: 0;
  }

  .section-light-bottom {
    background-image: linear-gradient(to bottom, #ffffff 15%, #f1f1f1 15%);
    width: 100%;
    min-height: 1100px;
    padding: 2rem;
  }

  .hero-panel {
    background-color: #ecbe7a;
    height: 200px;
    width: 600px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
  }
}

/* breakpoint sm */

@media screen and (max-width: 576px) {
  .mobile-hidden {
    visibility: hidden;
    display: none;
  }
}

/* breakpoint: xs */

@media screen and (max-width: 480px) {
  .mobile-hidden {
    visibility: hidden;
    display: none;
  }
}

/* container */

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1400px;
  }
}

/**** RESPONSIVE STYLES ****/

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
